import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
const firebaseConfig = {
  apiKey: "AIzaSyAsakHml490O_fPbTuwP8nhSfVPfT5_mFw",
  authDomain: "jart-a3042.firebaseapp.com",
  databaseURL: "https://jart-a3042.firebaseio.com",
  projectId: "jart-a3042",
  storageBucket: "jart-a3042.appspot.com",
  messagingSenderId: "849094435417",
  appId: "1:849094435417:web:a099c107f2db0c56074748"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();

// Firebase storage reference
export const storage = getStorage(app);