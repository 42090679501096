import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase-config";

function Home({ isAuth, setPath }) {
  const [postLists, setPostLists] = useState([]);
  const postCollectionRef = collection(db, "posts");
  const deletePost = async (id) => {
    const postDoc = doc(db, "posts", id);
    await deleteDoc(postDoc);
    getPosts();
  };

  const getPosts = async () => {
    const data = await getDocs(postCollectionRef);
    setPostLists(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    getPosts();
    setPath("/");
  }, []);

  const navigate = useNavigate();
  const handleMore = (post) => {
    const { titulo, file, url } = post;
    navigate("/publicacion", { state: { titulo, file, url } });
  };

  return (
    <section className="section">
      <div className="container">
        {postLists.length > 0 ? (
          postLists.map((post, index) => {
            return (
              <React.Fragment key={index}>
                <div className="card is-medium" >
                  <div className="card-content">
                    <div></div>

                    <div className="media">
                      <div className="media-content">
                        <p className="title is-4">{post.titulo}</p>
                        <p className="subtitle is-6">{post.autor}</p>
                        {/* <p className="subtitle is-6">22 Diciembre 2021</p> */}
                      </div>
                    </div>
                    <div className="content">
                      {post.cuerpo}<br />
                      <button
                        className="button is-danger"
                        onClick={() => handleMore(post)}
                      >
                        Leer!
                      </button>
                      <br />
                    </div>

                    <div className="deletePost">
                      {isAuth && post.encargado.id === auth.currentUser.uid && (
                        <button
                          onClick={() => {
                            deletePost(post.id);
                          }}
                        >
                          {" "}
                          &#128465;
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <br />
              </React.Fragment>
            );
          })
        ) : (
          <>
            <progress class="progress is-medium is-danger" max="100">45%</progress>
            <h3 className="title is-3">Cargando publicaciones</h3>
          </>
        )}
      </div>
    </section>
  );
}

export default Home;
