import React from "react";
import { useLocation } from "react-router-dom";

function Publicacion() {
  const { state } = useLocation();
  function b64toBlob(b64Data, contentType) {
    var byteCharacters = atob(b64Data);

    var byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      var slice = byteCharacters.slice(offset, offset + 512),
        byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  return (
    <section className="section fullHeight">
      <div className="container fullHeight">
        <div className="columns is-desktop">
          <div className="column">
            <h3 className="title is-3">{state.titulo}</h3>
          </div>
        </div>
        <div className="columns is-desktop fullHeight">
          <div className="column fullHeight">
            <iframe
              src={
                !state.file
                  ? state.url
                  : `data:application/pdf;base64,${state.file}#toolbar=0&navpanes=0&download=0`
              }
              width="100%"
              height="100%"
              style={{ height: "500px" }}
              toolbar={0}
              title={'publicacion'}
            ></iframe>
            {/* <embed className="fullHeight fullWidth" src={`data:application/pdf;base64,${state.file}`} /> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Publicacion;
