import { addDoc, collection } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db, storage } from "../firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function CreatePost({ isAuth }) {
  const [titulo, setTitulo] = useState("");
  const [blog, setBlog] = useState("");
  const [autor, setAutor] = useState("");
  const [cuerpo, setCuerpo] = useState("");
  const [file, setFile] = useState("");
  const [percent, setPercent] = useState(0);

  // Handle file upload event and update state
  function handleChangeFile(event) {
    setFile(event.target.files[0]);
  }

  const handleUpload = () => {
    if (!file) {
      alert("Por favor escoge un PDF primero!");
    }

    const storageRef = ref(storage, `/files/${file.name}`);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          createPost(url)
        });
      }
    );
  };


  const postsCollectionRef = collection(db, "posts");
  const postsQuechuaCollectionRef = collection(db, "postsQuechua");
  let navigate = useNavigate();



  const createPost = async (url) => {
    if (blog !== "") {
      if (blog === "quechua") {
        await addDoc(postsQuechuaCollectionRef, {
          titulo,
          cuerpo,
          autor,
          encargado: {
            name: auth.currentUser.displayName,
            id: auth.currentUser.uid,
          },
          file: false,
          url
        });
      } else {
        await addDoc(postsCollectionRef, {
          titulo,
          cuerpo,
          autor,
          encargado: {
            name: auth.currentUser.displayName,
            id: auth.currentUser.uid,
          },
          file: false,
          url
        });
      }
    }
    navigate("/");
  };

  const handleChange = (e) => {
    setBlog(e.target.value);
  };

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="createPostPage">
      <div className="container">
        <h2 className="title is-2">Crear una publicación</h2>
        <div className="field">
          <label className="label">Blog:</label>
          <div className="control">
            <label className="radio">
              <input
                type="radio"
                name="blog"
                value="jart"
                onChange={handleChange}
              />
              José Antonio Rocha Torrico
            </label>
            <label className="radio">
              <input
                type="radio"
                name="blog"
                value="quechua"
                onChange={handleChange}
              />
              Academia de Quechua
            </label>
          </div>
        </div>

        <div className="field">
          <label className="label">Titulo</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Título de la publicación"
              onChange={(event) => {
                setTitulo(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Autor</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Autor de la publicación"
              onChange={(event) => {
                setAutor(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Descripción corta</label>
          <div className="control">
            <textarea
              className="textarea"
              placeholder="Descripción corta"
              onChange={(event) => {
                setCuerpo(event.target.value);
              }}
            ></textarea>
          </div>
        </div>
        <div className="field">
          <label className="label">Documento en PDF:</label>
          <div className="control">
            <input type="file" name="file" onChange={handleChangeFile} />
          </div>
          <p>{percent} "% completado"</p>
        </div>

        <button className="button is-dark" onClick={handleUpload}>
          Publicar
        </button>
      </div>
    </div>
  );
}

export default CreatePost;
