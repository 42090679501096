import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import CreatePost from "./pages/CreatePost";
import Login from "./pages/Login";
import Academia from "./pages/Academia";
import React, { useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "./firebase-config";
// import "bulma/css/bulma.min.css";
import Publicacion from "./pages/Publicacion";

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [path, setPath] = useState("/");
  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      setIsAuth(false);
      window.location.pathname = "/login";
    });
  };

  return (
    <Router>
      <section
        className={`hero is-medium ${
          path === "/" ? "is-danger" : "is-warning"
        } is-bold`}
      >
        <div className="hero-head">
          <nav className="navbar">
            <div className="container">
              <div className="navbar-brand">
                <span className="navbar-burger" data-target="navbarMenuHeroA">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </div>
              <div id="navbarMenuHeroA" className="navbar-menu">
                <div className="navbar-end">
                  <Link to="/" className="navbar-item">
                    {" "}
                    Inicio{" "}
                  </Link>

                  <Link to="/academia-quechua" className="navbar-item">
                    {" "}
                    Academia de Quechua{" "}
                  </Link>
                  {!isAuth && (
                    <Link to="/login" className="navbar-item">
                      {" "}
                      Login{" "}
                    </Link>
                  )}
                  {isAuth && (
                    <Link to="/createpost" className="navbar-item">
                      {" "}
                      Publicar{" "}
                    </Link>
                  )}
                  {isAuth && (
                    <button onClick={signUserOut} className="navbar-item">
                      Log out
                    </button>
                  )}
                </div>
              </div>
            </div>
          </nav>
        </div>
        {path === "/" ? (
          <div className="hero-body">
            <div className="container">
            <div className="columns">
                <div className="column is-three-quarters">
                <h1 className="title">JOSÉ ANTONIO ROCHA TORRICO</h1>
              <h2 className="subtitle">Blog Personal</h2>
                </div>
                <div className="column">
                  <figure className="image is-128x128">
                    <img className="is-rounded" src="./jart.jpg" />
                  </figure>
                </div>
              </div>
              
            </div>
          </div>
        ) : (
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-three-quarters">
                  <h1 className="title">Academia de la Lengua Quechua</h1>
                  <h2 className="subtitle">Cochabamba</h2>
                </div>
                <div className="column">
                  <figure className="image is-128x128">
                    <img src="./quechua.png" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <section className="section fullHeight">
        <div className="container fullHeight">
          <Routes>
            <Route
              path="/"
              element={<Home isAuth={isAuth} setPath={setPath} />}
            />
            <Route
              path="/createpost"
              element={<CreatePost isAuth={isAuth} />}
            />
            <Route path="/login" element={<Login setIsAuth={setIsAuth} />} />
            <Route
              path="/academia-quechua"
              element={<Academia setPath={setPath} />}
            />
            <Route path="/publicacion" element={<Publicacion />} />
          </Routes>
        </div>
      </section>
    </Router>
  );
}

export default App;
