import React from "react";
import { auth, provider } from "../firebase-config";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function Login({ setIsAuth }) {
  let navigate = useNavigate();
  let users = ['aaron.yamil@gmail.com', 'naomicesped3s@gmail.com', 'juturi.1951@gmail.com '];
  const signInWithGoogle = () => {
    signInWithPopup(auth, provider).then((result) => {
      if(users.includes(result.user.email)){
        localStorage.setItem("isAuth", true);
        setIsAuth(true);
        navigate("/");
      }else{
        localStorage.setItem("isAuth", false);
        setIsAuth(false);
        navigate("/login");
      }
      
    });
  };

  return (
    <div className="loginPage">
      <p>Ingresa con Google</p>
      <button className="login-with-google" onClick={signInWithGoogle}>
        Ingresar
      </button>
    </div>
  );
}

export default Login;
